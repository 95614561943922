@import "../../styles/shared";

.pagination-container {
  display: flex;
  list-style-type: none;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0;
  padding: 0;

  .pagination-item {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 4px;
    font-weight: 600;
    font-feature-settings: "lnum" 1;
    

    &:not(.dots):hover {
      color: white;
      background-color: $orange;
      cursor: pointer;

      .arrow:before {
        border-color: white;
      }
    }

    &.selected {
      font-weight: 500;
      background-color: rgb(238	114	24);
      color: white;
      &:hover {
        cursor: default;
        color: white;
      }
    }

    .arrow {
      &::before {
        position: relative;
        content: "";
        display: inline-block;
        width: 0.6em;
        height: 0.6em;
        border-right: 3px solid $primary;
        border-top: 3px solid $primary;
      }

      &.left {
        transform: rotate(-135deg);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 3px solid rgba(0, 0, 0, 0.43);
        border-top: 3px solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
