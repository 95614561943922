@import '../../styles/shared';

header {
  
  background-color: rgb(66	29	44);
  .header-container {
    padding-top: 35px;
    padding-bottom: 200px;
    min-height: 485px;

    @include bp-sm-up {
      padding: 35px 0 170px;
    }

    overflow: hidden;
  background: url('../../assets/Anthos_hero_mobile.png') no-repeat top left;
  background-size: cover, auto;
  background-color: rgb(66	29	44);

  @include bp-sm-up {
    background: url('../../assets/Anthos_hero_desktop.png') no-repeat top left;
    background-size: cover, auto;
    background-color: rgb(66	29	44);
    min-height: 380px;
  }
  }

  .logo {
    display: inline-block;
    line-height: 0;
    margin-bottom: 15px;
  }

  .heading {
    font-size: 32px;
    font-weight: 900;
    color: $primary;
    max-width: 300px;
    line-height: 1.1;
  }
}
.message {
  text-align: center;
  background-color: rgb(17	61	101);
  padding: 10px 20px;
  color: #fff;

  .p1,
  .p2 {
    font-size: 14px;
    max-width: 990px;
    margin: 0 auto;
  }

  @include bp-sm-up {
    .p1,
    .p2 {
      font-size: 18px;
    }
  }

  .p1 {
    margin-bottom: 16px;
    @include bp-sm-up {
      margin-bottom: 0;
    }
  }
}
