@import "../../styles/shared";

#root{
  display: flex;
  flex-direction: column;
  height: 100vh;
}

footer{
  margin-top: auto !important;
}

.top-text {
  color: $primary;
  text-align: center;
  padding-top: 25px;
}

.search-wrap {
  margin: 1.5rem 0 1rem;
  color: $primary-text;
}

label {
  align-items: flex-start !important;

  span:nth-of-type(2) {
    padding-top: 9px;
  }
}

.wrap {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;

  &.pagination {
    @media (max-width: 600px) {
      margin-bottom: 20px;
      height: auto;
      gap: 15px;
      flex-direction: column;
    }
  }
}

.items {
  margin-bottom: 60px;
  .item {
    .item-title {
      color: $primary-text;
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
    }
    p {
      margin: 0.8rem 0 0;
      text-overflow: ellipsis;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .mt0{
      margin-top: 0px;
    }
    .download-btn-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      @include bp-sm-up {
        margin-top: 0;
        justify-content: flex-end;
      }
    }
  }
}
